import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import { media } from '../utils/style-utils'

import img1 from '../assets/images/ueber_uns_1.jpg'

const ImpressumWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  ${media.tabletDown`
    text-align: center;
  `};
`

const HaftungWrapper = styled.div`
  ${media.tabletDown`
    text-align: center;
    margin-top: 3em;
  `};
`

const Text = styled.div`
  width: 50%;
  ${media.tabletDown`
    width: 100%;
    text-align: center;
  `};
`

const Image = styled.div`
  flex: 0 1 auto;
  width: 40%;
  ${media.tabletDown`
    width: 100%;
  `};
`

const Spacer = styled.div`
  height: 2em;
  ${media.tabletDown`
    height: 1em;
  `};
`

const Impressum = ({ transition, location }) => (
	<Layout location={location}>
		<div style={transition && transition.style} className={'markdown'}>
			<ImpressumWrapper>
				<Text>
					<h1>Impressum</h1>
					<Spacer/>
					<p>
						Joachim Neuroth GmbH<br/>Messemarketing<br/>Peterstorstraße 21<br/>D-56410
						Montabaur<br/>Tel: +49(0)2602 180 313<br/>Fax: +49(0)2602 180 348<br/>Email:
						neuroth@neuroth-messemarketing.de
					</p>
					<Spacer/>
					<p>
						Eingetragen im Handelsregister beim AG Montabaur unter HRB 22925<br/>Geschäftsführer:
						Joachim Neuroth<br/>Ust-IdNr.: DE 281 326 394
					</p>
					<Spacer/>
					<p>Verantwortlich für den Inhalt: Joachim Neuroth</p>
				</Text>
				<Image>
					<img
						src={img1}
						alt={'Strichzeichnung Messestand'}
						style={{ width: '100%', height: 'auto' }}
					/>
				</Image>
			</ImpressumWrapper>
			<HaftungWrapper>
				<h1>Haftungsausschluss:</h1>
				<Spacer/>
				<h3>Inhalt des Online-Angebotes</h3>
				<p>
					Die Zusammenstellung der Informationen auf dieser Website wurde von
					Neuroth Messemarketing mit größtmöglicher Sorgfalt vorgenommen. Dennoch
					kann keinerlei Gewähr für Aktualität, Korrektheit, Vollständigkeit oder
					Qualität der bereitgestellten Informationen und Daten übernommen werden.
					Haftungsansprüche gegen Neuroth Messemarketing oder die Autoren bzw.
					Verantwortlichen dieser Website für Schäden materieller oder
					immaterieller Art, die auf ggf. fehlerhaften oder unvollständigen
					Informationen und Daten beruhen, sind, soweit nicht Vorsatz oder grobe
					Fahrlässigkeit vorliegt, ausgeschlossen.
				</p>
				<h3>Verweise und Links</h3>
				<p>
					Das Vorgesagte gilt auch für Informationen auf Websites, auf die mittels
					eines Hyperlinks verwiesen wird. Der Inhalt dieser Websites liegt
					vollständig außerhalb des Verantwortungsbereiches von Neuroth
					Messemarketing. Zum Zeitpunkt der Verlinkung waren Neuroth
					Messemarketing illegale Inhalte nicht bekannt. Neuroth Messemarketing
					hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und
					auf die Inhalte der gelinkten/verknüpften Seiten. Deshalb distanziert es
					sich hiermit ausdrücklich von allen Inhalten aller gelinkten/verknüpften
					Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung
					gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links
					und Verweise sowie für Fremdeinträge in Neuroth Messemarketing
					eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten. Für
					illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für
					Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener
					Informationen entstehen, haftet allein der Anbieter der Seite, auf
					welche verwiesen wurde, nicht derjenige, der über Links auf die
					jeweilige Veröffentlichung lediglich verweist.
				</p>
				<h3>Urheber- und Kennzeichenrecht</h3>
				<p>
					Neuroth Messemarketing ist bestrebt, in allen Publikationen die
					Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und
					Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente,
					Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
					Tondokumente, Videosequenzen und Texte zurückzugreifen.
				</p>
				<h3>Urheber- und Kennzeichenrecht</h3>
				<p>
					Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte
					geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
					Bestimmungen des jeweils gültigen Kennzeichenrechts und den
					Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund
					der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen
					nicht durch Rechte Dritter geschützt sind. Das Copyright für
					veröffentlichte, von Neuroth Messemarketing selbst erstellte Objekte
					bleibt allein bei Neuroth Messemarketing. Eine Vervielfältigung oder
					Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in
					anderen elektronischen oder gedruckten Publikationen ist ohne
					ausdrückliche Zustimmung von Neuroth Messemarketing nicht gestattet.
				</p>
			</HaftungWrapper>
		</div>
	</Layout>
)

export default Impressum
